import React from 'react'
import SEO from '../components/SEO'

export default function FourOhFourPage() {
    return (
        <>
            <SEO />
            <p>Hey, that page does not exist!</p>
        </>
    )
}
